import React, { useEffect, useState } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import Image from "next/image";
import { useRouter } from "next/router";
import { makeStyles } from "tss-react/mui";
import PoundIcon from "../../public/img/icons/pound.svg?svgr";
import PoundPurpleIcon from "../../public/img/poundPurple.svg";
import CopyLinkIcon from "../../public/img/icons/link.svg?svgr";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import JobBg from "../../public/img/jobBg.svg?svgr";
import Calendar from "../../public/img/calendar.svg?svgr";
import pin from "../../public/img/pin.png";
import hatIcon from "../../public/img/hat.svg";
import hatPurpleIcon from "../../public/img/hatPurple.svg";
import Location from "../../public/img/location.svg?svgr";
import heart from "../../public/img/heart.png";
import HeartFilled from "../../public/img/heart.svg?svgr";
import styles from "../../styles/jss/nextjs-material-kit/pages/componentsSections/jobDetailsStyleList.js";
import {
  useFavouritesAddRequest,
  useJobsListRequest,
  useStudentFavouritesListRequest,
} from "../../request/api";
import Pagination from "../../components/Pagination/Pagination";
import { format as formatDate } from "../../util/date";
import { getPageNumbersToLink } from "../../util/pagination";
import { buildSlug } from "../../util/url";
import Grid from "@mui/material/Grid";
import { isNumber } from "util";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useAuthContext } from "../../contexts/auth";
import { ROLE_SCHOOL_USER_ID } from "../../util/roles";
const useStyles = makeStyles()(styles);

export function getJobType(job) {
  if (job?.type === "course") {
    return "Course";
  }

  return job?.jobType;
}

export function getJobOrCourse(job) {
  if (job?.type === "course") {
    return "Course";
  }

  return "Job";
}

export function getSalary(job) {
  if (job?.salaryFee === "0") {
    if (job?.type === "course") {
      return "Free";
    } else {
      return "Unpaid";
    }
  }

  if (!isNaN(job?.salaryFee) && !isNaN(parseFloat(job?.salaryFee))) {
    return (
      "£" +
      parseFloat(job?.salaryFee).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      })
    );
  }

  if (typeof job?.salaryFee === "string" && job?.salaryFee.length > 1) {
    return job?.salaryFee[0].toUpperCase() + job?.salaryFee.slice(1);
  }

  return job?.salaryFee;
}

export function getDeadline(job) {
  if (job?.hideDeadline) {
    return "Ongoing";
  }

  return new Date(job?.deadline).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function Job({ job }) {
  const { classes, cx } = useStyles();
  const {
    isMutating: isUpdatingFavourites,
    trigger: addToFavourites,
    error: favouritesError,
  } = useFavouritesAddRequest(
    { type: "jobscourses", entityId: job?.id },
    undefined,
    { withCredentials: true }
  );
  const { auth } = useAuthContext();

  const {
    data: favouritesData,
    error: favouritesErrors,
    isLoading: isLoadingFavourites,
    mutate: updateFavourites,
  } = useStudentFavouritesListRequest(
    {
      type: "jobscourses",
    },
    {
      shouldFetch: !!auth.user,
    },
    { withCredentials: true }
  );

  const isFavourite = favouritesData
    ?.map((favourite) => favourite.entityId)
    .includes(job.id);
  const handleFavouriteToggle = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    await addToFavourites();

    await updateFavourites();
  };

  return (
    <Grid item key={job.id} style={{ padding: 0 }} xs={12} sm={12} md={12}>
      <Link
        href={`/${buildSlug(job.type)}/${buildSlug(
          job.organisation.name
        )}/${buildSlug(job.jobType)}/${buildSlug(job.title)}/${job.id}`}
      >
        <Card
          className={cx(
            classes.jobCard,
            "link",
            "jobcard",
            job.type == "course" ? "course" : null
          )}
        >
          <Grid
            container
            className={cx(classes.floatLeft, classes.jobTab, classes.bgWhite)}
          >
            <div className="triangle-container">
              {/*<div className="corner"></div>*/}
              {/* TODO we need to build pin position logic if we're to use this */}
              {/*<img className="pin-icon" src={pin.src} />*/}
            </div>
            <Grid
              item
              style={{
                backgroundImage: `url(${job.organisation.logo_uri})`,
                minHeight: "100px",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundColor: "#eaf6fe",
                backgroundOrigin: "content-box",
                padding: "10px",
              }}
              className={cx(classes.floatLeft)}
              xs={12}
              sm={12}
              md={2}
            ></Grid>
            <Grid item className={classes.cardContent} xs={11} md={9}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.cardTitle}>
                    <span>{job.title}</span>
                    <p className={classes.smallTitle}>
                      {job.organisation?.name}
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  className={cx(classes.floatLeft, classes.iconContent)}
                  style={{
                    display: "Flex",
                    width: "fit-content",
                  }}
                  xs={6}
                  md={3}
                >
                  <span
                    className={cx(classes.jobIcons, "slick-icons")}
                    style={{
                      margin: 0,
                      paddingBottom: "1px",
                    }}
                  >
                    <JobBg width={17} height={14} />
                  </span>
                  <strong className={classes.small}>{getJobType(job)}</strong>
                </Grid>
                <Grid
                  item
                  className={cx(classes.floatLeft, classes.iconContent)}
                  style={{
                    display: "Flex",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                  xs={6}
                  md={3}
                >
                  <span
                    className={cx(classes.jobIcons, "slick-icons")}
                    style={{
                      margin: 0,
                    }}
                  >
                    <Calendar width={18} height={14} />
                  </span>
                  <strong className={classes.small}>{getDeadline(job)}</strong>
                </Grid>

                <Grid
                  item
                  className={cx(classes.floatLeft, classes.iconContent)}
                  style={{
                    display: "Flex",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                  xs={6}
                  md={3}
                >
                  <span
                    className={cx(classes.jobIcons, "slick-icons")}
                    style={{
                      margin: 0,
                    }}
                  >
                    <PoundIcon width={15} height={14} />
                  </span>
                  <strong className={classes.small}>{getSalary(job)}</strong>
                </Grid>
                <Grid
                  item
                  className={cx(
                    classes.floatLeft,
                    classes.iconContent,
                    classes.locationIcon
                  )}
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    marginBottom: "5px",
                    width: "100%",
                    maxWidth: "100% !important",
                  }}
                >
                  <span
                    className={cx(classes.jobIcons, "slick-icons")}
                    style={{
                      margin: 0,
                    }}
                  >
                    <Location width={14} height={19} />
                  </span>
                  <strong
                    className={classes.small}
                    style={{ marginLeft: "7px" }}
                  >
                    {job.locationName}
                  </strong>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
                style={{ height: "100%" }}
              >
                <Grid item className={classes.sideIcons}>
                  <span
                    className={cx(classes.jobIcons, classes.socials)}
                    style={{
                      marginRight: "0px",
                    }}
                  >
                    {auth.user?.role === ROLE_SCHOOL_USER_ID && (
                      <>
                        <span onClick={handleFavouriteToggle}>
                          {isFavourite ? (
                            <>
                              <FavoriteIcon
                                height="16px"
                                className={cx(classes.icons, classes.jobIcons)}
                              />
                            </>
                          ) : (
                            <>
                              <FavoriteBorderIcon
                                height="16px"
                                className={cx(classes.icons, classes.jobIcons)}
                              />
                            </>
                          )}
                        </span>
                      </>
                    )}
                  </span>
                </Grid>
                <Grid item className={classes.sideIcons}>
                  <span
                    className={cx(
                      classes.side_Icon,
                      classes.side_Icon2,
                      classes.jobIcons,
                      classes.socials
                    )}
                    style={{
                      marginRight: "0px",
                      marginTop: "-6px",
                    }}
                  >
                    <CopyLinkIcon width={13} height={13} />
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Link>
    </Grid>
  );
}

export default function JobList(props) {
  // Can't destructure and also listen in `useEffect()` for changes.
  const { classes, cx } = useStyles();
  const router = useRouter();
  const perPage = 10;

  let currentPageNumber = router.query.page || 1;

  const { data, error, isLoading, mutate } = useJobsListRequest({
    limit: perPage,
    offset: perPage * (currentPageNumber - 1),
    query: props.jobQuery,
    ...props.jobFilters,
  });

  useEffect(() => {
    // Update if the page number changed, to minimise performance impact. We listen explicitly
    // for this because the pagination nav actions are shallow routed to avoid a full page reload.
    // SWR magic should lead the `useJobsListRequest()` hook to swap in the new page.
    mutate();
  }, [props.jobFilters, props.jobQuery, router.query.page]);

  const pagesToLink = data?.total_count
    ? getPageNumbersToLink(
        currentPageNumber,
        Math.ceil(data?.total_count / perPage)
      )
    : [1];

  return (
    <div className={cx(classes.section, classes.topPaddingZero)}>
      <div className={classes.marginAuto}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <p className={classes.pageInfo}>
              {data?.jobs?.length > 0 ? (
                <>
                  {1 + (currentPageNumber - 1) * perPage}&nbsp; to{" "}
                  {Math.min(data?.total_count, currentPageNumber * perPage)}
                  &nbsp; of {data?.total_count} results
                </>
              ) : isLoading ? (
                <>Loading&hellip;</>
              ) : (
                <>No matching jobs</>
              )}
            </p>
          </Grid>

          {data?.jobs?.length > 0
            ? data.jobs.map((job, i) => <Job job={job} key={i} />)
            : null}

          <Grid item xs={12} sm={12} md={12} className={classes.centred}>
            <Pagination
              color="primary"
              pages={pagesToLink.map((page) => {
                const params = {
                  page: page,
                };

                if (props.jobQuery) {
                  params['q'] = props.jobQuery;
                }

                return {
                  active: currentPageNumber == page,
                  disabled: false,
                  url: router.asPath.split('?')[0] + '?' + new URLSearchParams(params).toString(),
                  text: page,
                };
              }, pagesToLink)}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

JobList.propTypes = {
  jobFilters: PropTypes.shape({
    career_zone_id: PropTypes.number,
    job_type: PropTypes.string,
    location_id: PropTypes.number,
  }).isRequired,
  jobQuery: PropTypes.string,
};
